import { render, staticRenderFns } from "./MainCard.vue?vue&type=template&id=60639790&scoped=true"
import script from "./MainCard.vue?vue&type=script&lang=js"
export * from "./MainCard.vue?vue&type=script&lang=js"
import style0 from "./MainCard.vue?vue&type=style&index=0&id=60639790&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60639790",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2Announcement: require('/var/app/components/Theme2/Announcement.vue').default,Theme2DesktopFloatingIconsRankingButton: require('/var/app/components/Theme2/Desktop/FloatingIcons/RankingButton.vue').default,CommonsPopup: require('/var/app/components/Commons/Popup.vue').default})
